<template>
	<div class="table-responsive no-padding">
        <table class="table table-bordered table-striped">
			<thead>
				<tr>
					<th>STOCK</th>
					<th><center>ARTICULO</center></th>
					<th><center>CANT</center></th>
					<th><center>P.COMPRA<br>{{ config.monedasimbolo }}</center></th>
					<th><center>SUBTOTAL<br>{{ config.monedasimbolo }}</center></th>
                    <th><center>DESC<br>{{ config.monedasimbolo }}</center></th>
                    <th><center>TOTAL<br>{{ config.monedasimbolo }}</center></th>
                    <th></th>
				</tr>
			</thead>
			<tbody id="listaproducto">
				<tr v-for="(item, i) in tablaproducto" :key="i">
									
					<td><center>{{ item.saldoFisico }}</center></td>
									
					<td v-if="config.istotallistaproductoventa === 'ACTIVADO'"><span style="font-size:14px;">{{ item.nombre }}</span></td>
					<td v-if="config.istotallistaproductoventa === 'DESACTIVADO'"><span style="font-size:14px;">{{ item.codigo }} {{ item.marca }} {{ item.nombre }} {{ item.medida }} </span></td>
									
					<td 
                        bgcolor="#eeffd0" 
                        style="font-weight: 700;" 
                        @click="newCantidad = (i+'_'+item.producto_id); 
                        valueCantidad=item.cantidad"
                        >
						<label v-show="newCantidad != (i+'_'+item.producto_id)">{{ item.cantidad }}</label>
						<input 
                            style="width: 50px" 
                            class="myinput" 
                            v-if="newCantidad == (i+'_'+item.producto_id)" 
                            v-model="valueCantidad" 
                            @blur="editCantidad(i,item.producto_id); $emit('update')" 
                            @keyup.enter="editCantidad(i,item.producto_id); $emit('update')" 
                            @keyup="validacionCantidad()" 
                            autofocus="autofocus">
					</td>
									
					<template v-if="$can('adquisicion.modificar.precio.de.compra')">
						<td 
                            bgcolor="#ddf7ff" 
                            style="font-weight: 700;" 
                            @click="newPrecioCompra = (i+'_'+item.producto_id);
                            valuePrecioCompra=item.precioCompra"
                            >
							<label v-show="newPrecioCompra != (i+'_'+item.producto_id)">{{ item.precioCompra }}</label>
							<input  
                                style="width: 60px" 
                                class="myinput" 
                                v-if="newPrecioCompra == (i+'_'+item.producto_id)" 
                                v-model="valuePrecioCompra" 
                                v-on:blur="editPrecioCompra(i,item.producto_id); 
                                $emit('update')" 
                                @keyup.enter="editPrecioCompra(i,item.producto_id); 
                                $emit('update')" 
                                v-on:keyup="validacionDecimal()" 
                                autofocus="autofocus"
                                >
						</td>
					</template>

					<template v-else>
						<td bgcolor="#ddf7ff" style="font-weight: 700;">
							<label>{{ item.precioCompra }}</label>
						</td>
					</template>

                    <td>
						<span class="precios-sistema">{{ item.subtotal }}</span>
					</td>

					<td bgcolor="#fff1f1" style="font-weight: 700;">
						<label>{{ item.descuentoCompra }}</label>
					</td>

                    <td>
						<span class="precios-sistema">{{ item.importeTotalMoneda }}</span>
					</td>

					<td>
						<center>
							<a href="#" @click.prevent="deleteItem(i)">
								<a-icon type="close-circle" :style="{ fontSize: '20px', color: '#EE0808' }" />
							</a>
						</center>
					</td>
				</tr>
			</tbody>
			<tfoot>
				<tr>
					<th class="tabla-foot-prod"></th>
					<th class="tabla-foot-prod"></th>
					<th class="tabla-foot-prod"></th>
					<th class="tabla-foot-prod"><center><font size=3>Total.-</font></center></th>
					<th class="tabla-foot-prod"><center><font size=4 style="color:green;">{{ ViewSubtotal }}</font></center></th>
					<th class="tabla-foot-prod"><center style="color:#9f0303;"><font size=4>{{ ViewDescuentoVenta }}</font></center></th>
                    <th class="tabla-foot-prod">
						<center><font size=4 style="color:#005fa0;">{{ ViewImporteTotalMoneda }}</font></center>
					</th>
				</tr>
			</tfoot>
	    </table>

        <!-- EDITAR PRECIOS DE VENTA -->
        <a-modal
            v-model="openModalPrecioVenta"
            title="ASIGNAR PRECIOS DE VENTA AL PRODUCTO"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="920"
            :zIndex="1040"
            >

            <div class="mb-12">
                <ModuloPrecioVenta 
                    :monedasimbolo="config.monedasimbolo"
                    :utilidadporcentaje="config.utilidadporcentaje"
                    :preciocifradecimal="config.preciocifradecimal"
                    :preciocifraentera="config.preciocifraentera"
                    :precioreglatres="config.precioreglatres"
                    :almacenid="this.consulta.almacenID"
                    :productoid="this.consulta.productoID"
                    :proveedorid="this.consulta.proveedorID"
                    :utilidad="this.consulta.utilidad"
                    :producto="this.consulta.producto"
                    :preciocompra="this.consulta.precioCosto"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="openModalPrecioVenta = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>
        <!-- EDITAR PRECIOS DE VENTA -->

        <!-- ACTUALIZAR VENCIMIENTO -->
        <a-modal
            v-model="showModalActualizarVencimiento"
            title="Actualizar fecha de Vencimiento"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="560"
            :zIndex="1049"
            >

            <div class="row mb-12">
                <ComponenteActualizarVencimiento
                    :producto="this.consulta.producto"
                    @successActualizarVencimiento="dataSuccessActualizarVencimiento"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="showModalActualizarVencimiento = false">
                    Cancelar
                </a-button>
            </template>
        </a-modal>
        <!-- ACTUALIZAR VENCIMIENTO -->
	</div>
</template>

<script>
	import * as utils from '@/utils/utils'
	import { mapGetters, mapActions } from 'vuex'
    import ModuloPrecioVenta from '../pages/Inventario/Modulos/ModuloPrecioVenta.vue'
    import ComponenteActualizarVencimiento from '../pages/Inventario/Components/ComponenteActualizarVencimiento.vue'

	export default {

        props: {
			listaproducto: { type: Object, required: true },
            estadomodulocompra: { type: String, required: true },
            nuevaventanacompraid: { type: Number, required: true },
            almacenID: { type: Number, required: false },
            proveedorID: { type: Number, required: false }
		},

        components: {
            ModuloPrecioVenta,
            ComponenteActualizarVencimiento
        },

		data () {
			return {
                utils,
                newCantidad: null,
                valueCantidad: null,
                newPrecioCompra: null,
                valuePrecioCompra: null,
                valueDescuentoCompra: '0.00',
                itemDescuentoCompra: null,
                tablaproducto: [],
                openModalPrecioVenta: false,
                showModalActualizarVencimiento: false,
                consulta: {
                    almacenID: this.almacenID,
                    proveedorID: this.proveedorID,
                    productoID: '',
                    utilidad: '',
                    producto: {},
                    precioCosto: ''
                },
                tablaVentanaNuevaID: 'tablaproductocompra' + this.nuevaventanacompraid,
                buttonKeyPrecioVenta: 100,
                buttonKeyFechaVencimiento: 200
			}
		},

		mounted () {
            if (this.estadomodulocompra === 'AGREGAR') {
				this.getLocalStorage()
                this.enviarInformacion()
			} else if (this.estadomodulocompra === 'EDITAR') {
				this.tablaproducto = []
			}
        },

        computed: {
            ...mapGetters('configuracion', ['config']),

            Subtotal () {
                return this.calculateTotal('subtotal')
            },

            DescuentoCompra () {
                return this.calculateTotal('descuentoCompra')
            },

            ImporteTotalMoneda () {
                const importeTotal = this.calculateTotal('importeTotalMoneda')
                if (this.DescuentoCompra === 0) {
                    if (this.Subtotal !== importeTotal) {
                        utils.openNotificationWithIcon('info', 'Mensaje : ', 'Atencion !!! : No es igual el proceso del calculo, vuelva a realizar el calculo...', 'topRight')
                    }
                    return importeTotal
                }
                return importeTotal
            },

            // VIEWS PROPERTY COMPUTERS //

            ViewImporteTotalMoneda () {
                return utils.formatMoney(this.ImporteTotalMoneda, this.config.compradecimales, '.', '')
            },

            ViewSubtotal () {
                return utils.formatMoney(this.Subtotal, this.config.compradecimales, '.', '')
            },

            ViewDescuentoVenta () {
                return utils.formatMoney(this.DescuentoCompra, this.config.compradecimales, '.', '')
            }
        },

		methods: {
            calculateTotal (property) {
                const sum = this.tablaproducto.reduce((accumulator, current) => {
                    return accumulator + parseFloat(current[property])
                }, 0)
                return parseFloat(sum)
            },

            enviarInformacion () {
                this.$emit('subtotal', this.Subtotal)
                this.$emit('descuento', this.DescuentoCompra)
                this.$emit('total', this.ImporteTotalMoneda)
                this.$emit('productos', this.tablaproducto)
                this.$emit('proveedorID', this.consulta.proveedorID)
            },

            getLocalStorage () {
                const datosDB = JSON.parse(localStorage.getItem(this.tablaVentanaNuevaID))
                this.tablaproducto = datosDB === null ? [] : datosDB
                return this.tablaproducto
            },

            saveLocalStorage () {
                localStorage.setItem(this.tablaVentanaNuevaID, JSON.stringify(this.tablaproducto))
            },

            removeItemLocalStorage () {
                localStorage.removeItem(this.tablaVentanaNuevaID)
            },
            
            agregarProductoALaLista (producto) {
                this.consulta.proveedorID = producto.proveedorID

                if (this.tablaproducto.length === 0) {
                    this.agregarProductoNuevo(producto)
                } else {
                    const productoExistente = this.tablaproducto.find(element => parseInt(element.producto_id) === parseInt(producto.producto_id))
                    if (productoExistente) {
                        this.notificarProductoExistente(productoExistente)
                    } else {
                        this.agregarProductoExistente(producto)
                    }
                }

                this.enviarInformacion()
            },

            agregarProductoNuevo (producto) {
                this.tablaproducto.push(producto)
                // LOCAL STORAGE
                this.saveLocalStorage()
            },

            agregarProductoExistente (producto) {
                this.tablaproducto.push(producto)
                // LOCAL STORAGE
                this.saveLocalStorage()
            },

            notificarProductoExistente (productoExistente) {
                utils.openNotificationWithIcon('info', 'Mensaje : ', 'El Producto ' + productoExistente.nombre + ' ' + productoExistente.medida + ' ya fue elegido...', 'topRight')
            },

            mostrarAlertaPrecioCero () {
                utils.openNotificationWithIcon('info', 'Mensaje : ', 'Por favor agregue Precios de Ventas al Producto segun la Sucursal donde pertenezca...', 'topRight')
            },
            
            // Gestion de Tabla del Producto
            validacionCantidad () {
                this.valueCantidad = (this.valueCantidad + '').replace(/[^0-9.]/g, '')
            },

            validacionDecimal () {
                this.valuePrecioCompra = (this.valuePrecioCompra + '').replace(/[^0-9.]/g, '')
            },

			validacionDecimalDescuentoCompra () {
                this.valueDescuentoCompra = (this.valueDescuentoCompra + '').replace(/[^0-9.]/g, '')
            },

            editCantidad (i, j) {
                this.newCantidad = ''
                // Verificar si la cantidad es válida
                if (parseFloat(this.valueCantidad) === 0.000000 || this.valueCantidad === '') {
                    utils.openNotificationWithIcon('info', 'Mensaje : ', 'Atencion !!! : Introduzca una Cantidad para realizar la compra ...', 'topRight')
                } else {
                    // Asignar la cantidad y calcular el subtotal
                    const cantidad = parseFloat(this.valueCantidad)
                    const subtotal = cantidad * parseFloat(this.tablaproducto[i].precioCompra)

                    // Redondeo según la configuración
                    const roundedSubtotal = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.redondearNumero(subtotal) : subtotal
                    const formatoSubtotal = utils.formatMoney(roundedSubtotal, this.config.compradecimales, '.', '')

                    const descuento = subtotal - parseFloat(this.valueDescuentoCompra)
                    const subtotalActualizado = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.formatMoney(utils.redondearNumero(descuento), this.config.compradecimales, '.', '') : utils.formatMoney(descuento, this.config.compradecimales, '.', '')

                    // Actualizar los valores en la tabla
                    this.tablaproducto[i].cantidad = cantidad
                    this.tablaproducto[i].subtotal = formatoSubtotal
                    this.tablaproducto[i].importeTotalMoneda = subtotalActualizado
                    
                    // Guardar en el almacenamiento local si estamos en modo de agregar
                    if (this.estadomodulocompra === 'AGREGAR') {
                        this.saveLocalStorage()
                    }

                    this.enviarInformacion()
                }
            },

            editPrecioCompra (i, j) {
                this.newPrecioCompra = ''

                if (parseFloat(this.valuePrecioCompra) === 0.000000 || this.valuePrecioCompra === '') {
                    utils.openNotificationWithIcon('info', 'Mensaje : ', 'Atencion !!! : Introduzca un precio de compra para realizar la compra ...', 'topRight')
                } else {
                    const precioCompra = parseFloat(this.valuePrecioCompra)
                    const cantidad = parseFloat(this.tablaproducto[i].cantidad)
                    const subtotal = parseFloat(cantidad * precioCompra)

                    const roundedSubtotal = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.redondearNumero(subtotal) : subtotal
                    const formatoSubtotal = utils.formatMoney(roundedSubtotal, this.config.compradecimales, '.', '')

                    const descuento = subtotal - parseFloat(this.valueDescuentoCompra)
                    const subtotalActualizado = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.formatMoney(utils.redondearNumero(descuento), this.config.compradecimales, '.', '') : utils.formatMoney(descuento, this.config.compradecimales, '.', '')

                    // Verificar si la validación de editar precio de venta está deshabilitada
                    if (this.config.deshabilitarvalidareditarprecioventa === 'ACTIVADO' || precioCompra >= parseFloat(this.tablaproducto[i].precioCompra)) {
                        // Asignar el nuevo precio de venta
                        this.tablaproducto[i].precioCompra = utils.formatMoney(precioCompra, this.config.compradecimales, '.', '')
                    } else {
                        utils.openNotificationWithIcon('info', 'Mensaje : ', 'El valor ingresado es menor al Precio de Compra actual. Por favor, vuelva a iniciar de nuevo la compra...', 'topRight')
                        return false
                    }

                    // Actualizar el subtotal en la tabla
                    this.tablaproducto[i].subtotal = formatoSubtotal
                    this.tablaproducto[i].importeTotalMoneda = subtotalActualizado

                    // Guardar en el almacenamiento local si estamos en modo de agregar
                    if (this.estadomodulocompra === 'AGREGAR') {
                        this.saveLocalStorage()
                    }

                    this.enviarInformacion()
                }
            },

            editItemDescuentoCompra (i, j) {
                this.itemDescuentoCompra = ''

                if (this.valueDescuentoCompra === '') {
                    utils.openNotificationWithIcon('info', 'Mensaje : ', 'Atencion !!! : Introduzca un descuento para realizar la compra ...', 'topRight')
                } else {
                    // Validar si el descuento es mayor que el subtotal
                    if (parseFloat(this.valueDescuentoCompra) > parseFloat(this.tablaproducto[i].subtotal)) {
                        utils.openNotificationWithIcon('info', 'Mensaje : ', 'El valor de descuento es mayor al Precio de Compra actual. Por favor, vuelva a iniciar de nuevo la compra...', 'topRight')
                        return false
                    } 
                    
                    // Asignar el nuevo descuento de venta
                    this.tablaproducto[i].descuentoCompra = utils.formatMoney(this.valueDescuentoCompra, this.config.compradecimales, '.', '')
                    
                    // Calcular el nuevo importe this.tablaproducto[i].subtotal) - parseFloat(this.valueDescuentoVentatotal en la moneda
                    const descuento = parseFloat(this.tablaproducto[i].subtotal) - parseFloat(this.valueDescuentoCompra)
                    const subtotalActualizado = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.formatMoney(utils.redondearNumero(descuento), this.config.compradecimales, '.', '') : utils.formatMoney(descuento, this.config.compradecimales, '.', '')

                    this.tablaproducto[i].importeTotalMoneda = subtotalActualizado

                    // Guardar en el almacenamiento local si estamos en modo de agregar
                    if (this.estadomodulocompra === 'AGREGAR') {
                        this.saveLocalStorage()
                    }

                    this.enviarInformacion()
                }
            },

			deleteItem (i, j) {
                this.tablaproducto.splice(i, 1)

				if (this.estadomodulocompra === 'AGREGAR') {
                	this.saveLocalStorage()
				}
                this.enviarInformacion()
                this.$root.$emit('llamarResetDescuendoMoneda')
            },

            vaciarListaProductos () {
                this.tablaproducto = []
                localStorage.removeItem(this.tablaVentanaNuevaID)
                this.enviarInformacion()
            },

            verificarProveedor (proveedorID) {
                this.consulta.proveedorID = proveedorID
            },

            verificarSucursal (sucursalID) {
                this.consulta.sucursalID = sucursalID
            },

            // Precio de Venta y Fechas
            abrirModalAsignarPrecios (item) {
                if (parseInt(this.almacenID) !== parseInt(item.almacenId)) {
					utils.openNotificationWithIcon('info', 'Mensaje : ', 'La Sucursal que ha elegido no es igual a la sucursal donde pertenece el producto.', 'topRight')
				} else if (parseInt(this.consulta.proveedorID) !== parseInt(item.proveedorID)) {
					utils.openNotificationWithIcon('info', 'Mensaje : ', 'El Proveedor que ha elegido no es igual al proveedor donde pertenece el producto..', 'topRight')
				} else {
                    this.consulta.producto = {}

                    setTimeout(function () { 
                        this.consulta.producto = item
                        this.consulta.tipoprecioventaID = 1
                        this.consulta.almacenID = item.almacenId
                        this.consulta.productoID = item.producto_id
                        this.consulta.proveedorID = item.proveedorID
                        this.consulta.utilidad = item.porcentajeUtilidad
                        this.consulta.precioCosto = item.precioCompra
                        this.openModalPrecioVenta = true
                    }.bind(this), 300)
                    this.buttonKeyPrecioVenta++
                }
			},

            abrirModalActualizarVencimiento (item) {
                this.consulta.producto = {}

                setTimeout(function () { 
                    this.consulta.producto = item
                    this.showModalActualizarVencimiento = true
                    this.buttonKeyFechaVencimiento++
                }.bind(this), 500)
            },

            dataSuccessActualizarVencimiento (value) {   
            }
		}
	}
</script>