<template>
    <div v-if="$can('correccion.menu.ajuste')">
        <ModuloAjusteEntrada 
            :nuevaventanaid="1"
            :estadomodulo="`AGREGAR`"
        />
    </div>
</template>
    
<script>
    import ModuloAjusteEntrada from './Modulos/ModuloAjusteEntrada.vue'

    export default {
    
        components: {
            ModuloAjusteEntrada
        },
    
        data () {
            return {
                spinnerloading: false
            }
        }
    }
</script>